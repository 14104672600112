import React, { useState } from "react";
import { Typography, CssBaseline, AppBar, Toolbar, Container, TextField, Button, Grid, Dialog, Avatar, Pagination } from "@mui/material";
import SetMealIcon from '@mui/icons-material/SetMeal';
import Stack from '@mui/material/Stack';
import useStyles from "./styles";



const messages = [
    {
        nick: "学习哥",
        amount: 10000,
        time: new Date().getTime(),
        msg: "测试一下你这牛逼的程序怎样"
    },
    {
        nick: "学习哥2",
        amount: 2000,
        time: new Date().getTime(),
        msg: "测试一下你这牛逼的程序怎样2"
    },
    {
        nick: "学习哥3",
        amount: 10000,
        time: new Date().getTime(),
        msg: "测试一下你这牛逼的程序怎样"
    },
    {
        nick: "学习哥4",
        amount: 2000,
        time: new Date().getTime(),
        msg: "测试一下你这牛逼的程序怎样2"
    },
    {
        nick: "学习哥5",
        amount: 10000,
        time: new Date().getTime(),
        msg: "测试一下你这牛逼的程序怎样"
    },
    {
        nick: "学习哥6",
        amount: 2000,
        time: new Date().getTime(),
        msg: "测试一下你这牛逼的程序怎样2"
    },
    {
        nick: "学习哥7",
        amount: 10000,
        time: new Date().getTime(),
        msg: "测试一下你这牛逼的程序怎样"
    },
];



function MessageBoard() {

    const PAGENUM = 5;

    let firstPage = [];
    for (let index = 0; index < PAGENUM; index++) {
        const element = messages[index];
        if (element) {
            firstPage[index] = element;
        } else {
            break;
        }
    }

    const [page, setPage] = useState(firstPage);

    const onChange = (_, page) => {
        let list = [];
        for (let index = 0; index < PAGENUM; index++) {
            const element = messages[index+(page-1)*PAGENUM];
            if (element) {
                list[index] = element;
            } else {
                break;
            }
        }
        setPage(list);
    }

    return (
        <Stack spacing={2}>
            <Typography variant="h5" fontFamily="ZCOOL QingKe HuangYou" style={{margin: "50px 0 30px"}}>有钱就是爷</Typography>
            {
                page.map((item, i) => (
                    <div key={i}>
                        <Avatar alt="Remy Sharp" src="./heads/2.jpeg" style={{float: "left"}}/>
                        <span style={{color: "#222222", fontWeight:"bold"}}>{item.nick}</span> <span>于 {new Date(item.time).toLocaleDateString()} 向他捐赠</span> <span style={{color: "#ee0000", fontWeight:"bold"}}>{item.amount}</span>  <span>¥</span> <br/>
                        <h4 style={{paddingLeft: "0px", marginTop: "10px", color: "#90a4ae"}}>“{item.msg}”</h4>    
                    </div>  
                ))
            }
            {
                messages.length > PAGENUM && 
                <div style={{justifyContent:"center", display: "flex", marginTop:"50px"}} fullWidth>
                    <Pagination count={Math.ceil(messages.length/PAGENUM)} variant="outlined" shape="rounded" style={{margin: "0 auto"}} onChange={onChange}/>
                </div>
            }

        </Stack>
    )
}


function PayDialog() {

    const scale = 0.7;

    return (
    <div style={{padding: "20px", backgroundColor: "#fafafa"}}>
        <h3>谢谢各位程序猿帮我暴富!</h3>

        <div>
            <img
                src="./wechatpay.png"
                alt="微信支付"
                width={300 * scale}
                height={400 * scale}
            />
            &nbsp;&nbsp;
            <img
                src="./alipay.png"
                alt="阿里支付"
                width={300 * scale}
                height={400 * scale}
            />
        </div>
    </div>
    )
}


function PlayerAction() {

    const [payOpen, setPayOpen] = React.useState(false);

    const handlePayClose = () => {
        setPayOpen(false);
    }
    return (
        <div>
            <Typography variant="h5" fontFamily="ZCOOL QingKe HuangYou" style={{margin: "50px 0 10px"}}>用金钱羞辱他</Typography>
            <div style={{margin: "10px 0", color: "#90a4ae"}}>
                <span>截止 2021/11/28 共有5人,&nbsp;羞辱过本穷逼的金钱共计</span> <span style={{color: "#ee0000", fontWeight:"bold"}}>19000</span> <span>¥</span>
            </div>
            <TextField
                fullWidth
                margin="normal"
                required
                label="昵称"
                defaultValue="一个好人"
                variant="standard"
            />
            <br/>
            <TextField
                fullWidth
                margin="normal"
                required
                label="邮箱"
                defaultValue="help@me.com"
                variant="standard"
            />
            <br/>
            <TextField
                fullWidth
                margin="normal"
                required
                label="说点什么"
                defaultValue="过错是暂时的遗憾, 而错过则是永远的遗憾!"
                variant="standard"
            />
            <br/>
            <TextField
                fullWidth
                margin="normal"
                required
                label="验证 (付款订单号或付款小数部分)"
            />
            <Grid container spacing={2} justifyContent="center" marginTop="10px">
                <Grid item style={{color: "red", width: "50%"}}>
                    <Button variant="contained" color="primary" fullWidth onClick={() => {
                        setPayOpen(true);
                    }}>
                        先给钱羞辱
                    </Button>
                    <Dialog
                        open={payOpen}
                        onClose={handlePayClose}
                    >
                        <PayDialog/>
                    </Dialog>
                </Grid>
                <Grid item style={{color: "red", width: "50%"}}>
                    <Button variant="outlined" color="secondary" fullWidth>
                        再留言嘲讽
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}



function App() {
    const classes = useStyles();

    var player = new window.Player({});
    player.loop = false;
    // player.play("273549538", {target: "auto"});

    return (
        <>
            <CssBaseline/>
            <AppBar position="relative">
                <Toolbar>
                    <SetMealIcon className={classes.icon}/>
                    <Typography variant="h6">
                        要饭人生
                    </Typography>
                </Toolbar>
            </AppBar>
            <main>
                <div className={classes.container}>
                    <Container maxWidth="sm">
                        <Typography variant="h3" align="center" color="textPrimary" gutterBottom fontFamily="ZCOOL QingKe HuangYou">
                            要饭人生
                        </Typography>
                        <Typography variant="h5" align="center" color="textSecondary" paragraph fontFamily="Zhi Mang Xing">
                            “10块钱吃不了一顿饭，买不了车买不了房，娶不到媳妇。不如给我，让我暴富！”
                        </Typography>
                        <PlayerAction/>
                        <MessageBoard/>
                    </Container>
                </div>
            </main>
            <footer className={classes.footer}>
                <Typography variant="h6" align="center" color="textPrimary" gutterBottom>
                    {/* Footer */}
                    <hr/>
                </Typography>
                <Typography variant="subtitle1" align="center" color="textSecondary">
                    Powered by React and Vercel
                </Typography>
            </footer>
        </>
    );
}



export default App;
