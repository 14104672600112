import { makeStyles } from "@mui/styles"


const useStyles = makeStyles((theme) => ({

    icon: {
        marginRight: '20px'
    },
    buttons: {
        marginTop: '40px'
    },
    container: (props) => ({
        background: props.background,
        padding: '80px 0 60px'
    }),
    cardGrid: {
        padding: '20px 0'
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    cardMedia: {
        paddingTop: '56.25%'
    },
    cardContent: {
        flexGrow: 1
    },
    footer: {
        padding: '50px 0'
    }
}))










export default useStyles;